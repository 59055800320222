.term-box .container .content {
	box-shadow: 0px 0px 16px #0000000f;
	border-radius: 10px;
}

.term-box .container .content .box h6 {
	font: var(--text-font-h6);
	letter-spacing: 0px;
}
.term-box .container .content .box p {
	font: var(--text-font-p-3);
	letter-spacing: 0px;
}

.term-box .container .content .sub-list {
	padding-right: 30px;
	margin-bottom: 14px;
}
.term-box .container .content .sub-list p {
	font-size: 18px;
	font-weight: 400;
}

@media (max-width: 768px) {
	.term-box .container .content .sub-list {
		padding-right: 20px;
	}
	.term-box .container .content .sub-list p {
		font-size: 15px;
	}
}
