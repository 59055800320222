@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700&display=swap");

html {
	scroll-behavior: smooth;
}

:root {
	--text-color-01: #000000;
	--text-color-02: #e468ca;
	--text-color-03: #66ccff;
	--text-color-04: #fff;
	--transition-01: all linear 0.2s;
}

:root {
	--text-font-h1: normal normal bold 78px/147px Cairo;
	--text-font-h1-3: normal normal bold 34px/63px Cairo;

	--text-font-h1-2: normal normal bold 38px/72px Cairo;
	--text-font-h2: normal normal bold 28px/52px Cairo;
	--text-font-h4: normal normal 400 24px/45px Cairo;
	--text-font-p: normal normal 600 24px/45px Cairo;
	--text-font-p-2: normal normal 600 24px/45px Cairo;
	--text-font-p-3: normal normal normal 20px/37px Cairo;
	--text-font-h5: normal normal normal 20px/45px Cairo;
	--text-font-h6: normal normal 600 24px/37px Cairo;
	--text-font-h6-5: normal normal 600 24px/37px Cairo;
	--text-font-h6-2: normal normal normal 18px/33px Cairo;
	--text-font-h4-2: normal normal bold 24px/45px Cairo;
	--text-font-h6-3: normal normal normal 24px/45px Cairo;
}

@media (max-width: 1300px) {
	:root {
		--text-font-p-2: normal normal 600 22px/45px Cairo;
		--text-font-h1-2: normal normal bold 33px/72px Cairo;
		--text-font-h1-3: normal normal bold 30px/63px Cairo;

		--text-font-h1: normal normal bold 58px/147px Cairo;
		--text-font-h2: normal normal bold 24px/52px Cairo;
		--text-font-h4: normal normal 400 18px/45px Cairo;
		--text-font-h4-2: normal normal bold 24px/45px Cairo;
		--text-font-p: normal normal 600 18px/45px Cairo;
		--text-font-p-2: normal normal 600 18px/45px Cairo;
		--text-font-p-3: normal normal 600 18px/45px Cairo;

		--text-font-h5: normal normal normal 20px/45px Cairo;
		--text-font-h6: normal normal 600 22px/37px Cairo;
		--text-font-h6-2: normal normal normal 18px/33px Cairo;
		--text-font-h6-3: normal normal normal 24px/45px Cairo;
	}
}

@media (max-width: 768px) {
	:root {
		--text-font-h6-3: normal normal normal 22px/45px Cairo;

		--text-font-p-2: normal normal 600 21px/45px Cairo;
		--text-font-h1-2: normal normal bold 28px/72px Cairo;
		--text-font-h1-3: normal normal bold 27px/63px Cairo;
		--text-font-h4-2: normal normal bold 20px/45px Cairo;

		--text-font-h1: normal normal bold 48px/147px Cairo;
		--text-font-h2: normal normal bold 20px/52px Cairo;
		--text-font-h4: normal normal 400 20px/45px Cairo;
		--text-font-p-2: normal normal 600 17px/45px Cairo;
		--text-font-p-3: normal normal 400 16px/45px Cairo;
		--text-font-p: normal normal 600 17px/45px Cairo;
		--text-font-h5: normal normal normal 18px/45px Cairo;
		--text-font-h6: normal normal 600 20px/37px Cairo;
		--text-font-h6-5: normal normal 600 19px/37px Cairo;
		--text-font-h6-2: normal normal normal 18px/33px Cairo;
	}
}

.tn {
	transition: var(--transition-01);
}

.container {
	max-width: 1400px !important;
}

* {
	font-family: "Cairo", sans-serif !important;
	transition: var(--transition-01);
	transition-timing-function: linear !important;
}

.box-store {
	margin-top: 20px;
	display: flex;
	justify-content: center !important;
	align-items: center !important;
}

@media (max-width: 380px) {
	.box-store {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
	}

	.section-1 {
		height: 1620px;
	}
}

.box-store a {
	width: 167px;
	height: 56px;
	display: block;
	cursor: pointer;
}

@media (max-width: 767px) {
	.box-store a {
		width: 140px;
		height: 56px;
	}

	.box-store a svg,
	.box-store a img {
		height: 56px;
	}
}

.box-store a svg,
.box-store a img {
	max-width: 100%;
}

/* start-img */
.featuer .box-img-order {
	position: relative;
}

.featuer .box-img-order .box-img img {
	width: 100%;
	height: 100%;
}

.featuer .box-img-order .box-svg {
	position: absolute;
	width: 50%;
	height: 100%;
	/* top: 120px; */
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	z-index: -1;
}

.featuer .box-img-order .box-svg svg {
	width: 100%;
	height: 100%;
}

@media (max-width: 768px) {
	.featuer .box-img-order {
		position: relative;
		height: 500px;
	}

	.featuer .box-img-order .box-img {
		width: 100%;
		height: 100%;
	}

	.featuer .box-img-order .box-img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.featuer .box-img-order .box-svg {
		position: absolute;
		width: 55%;

		top: 100px;
	}
}

@media (max-width: 605px) {
	.featuer .box-img-order .box-svg {
		width: 65%;
	}
}

@media (max-width: 480px) {
	.featuer .box-img-order .box-svg {
		width: 85%;
	}
}

/* end-img */
/* start-hero */

.section-1 {
	/* opacity: 0.16; */
	position: relative;
}

.section-1::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: transparent
		linear-gradient(
			180deg,
			var(--unnamed-color-0ab3d0) 0%,
			var(--unnamed-color-e468ca) 100%
		)
		0% 0% no-repeat padding-box;
	background: transparent linear-gradient(180deg, #66ccff 0%, #e468ca 100%) 0%
		0% no-repeat padding-box;

	border-radius: 0px 0px 150px 0px;
	opacity: 0.16;
	z-index: -1;
}

@media (max-width: 768px) {
	.section-1::before {
		border-radius: 0px 0px 100px 0px;
	}
}

.section-1 .header-box {
	background-color: transparent !important;
	min-height: auto !important;
	padding-top: 22px;
}

.section-1 .hero .box-right h1 {
	font: var(--text-font-h1);
}

.section-1 .hero .box-right h2 {
	font: var(--text-font-h2);
	color: var(--text-color-01);
	margin-top: 20px;
	margin-bottom: 10px;
}

.section-1 .hero .box-right p {
	font: var(--text-font-p-2);
}

.section-1 .hero .box-right h4 {
	font: var(--text-font-h4);
	margin-top: 48px;
}

.section-1 .hero .box-left {
	position: relative;
	height: 100%;
}

.section-1 .hero .box-left .box-img {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 100px;
}

.section-1 .hero .box-left .box-img img {
	height: 100%;
	object-fit: cover;
}

/* end-hero */

/* start-features */

.featuer .box-left .box-content {
	box-shadow: 0px 6px 12px #e468ca1a;
	opacity: 1;
}

.featuer .box-left .box-content .top h4 {
	font: var(--text-font-h4);
}

@media (max-width: 768px) {
	.featuer .box-left .box-content .top h4 {
		font: var(--text-font-h4);
		font-weight: 700;
	}
}

.featuer .box-left .box-content ul li {
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: start;
	align-items: center;
}

.featuer .box-left .box-content ul li h4 {
	font: var(--text-font-h5);
}

/* end-features */
/* start-book */

.book .container .box-content .box {
	box-shadow: 4px 12px 24px #fcedf9;
	/* padding: 72px 53px 0; */
}

.book .container .box-content .box p {
	font: var(--text-font-p);
}

.book .container .box-content .box h4 {
	font: var(--text-font-h4);
}

.book .container .box-content .box a {
	border: 1px solid #0ab3d0;
	height: 60px;
	background-color: #ffffff;
	color: #0ab3d0;
	font: var(--text-font-p);
}

/* end-book */

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background: transparent linear-gradient(180deg, #66ccff 0%, #e468ca 100%) 0%
		0% no-repeat padding-box;
	cursor: pointer;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
}
