.header .logo img {
	max-width: 100%;
	max-height: 100%;
}

.header .link ul a {
	cursor: pointer;
	color: var(--text-color-01);
	border-bottom: 2px solid transparent;
	font-weight: 600;
	transition: var(--transition-01);
}

.header .link ul a.active,
.header .link ul a:hover {
	font-weight: 700 !important;
	border-color: var(--text-color-01);
}

@media (min-width: 768px) {
	.header .link ul a {
		font-size: 18px;
	}
}

.header-box .menu-link {
	display: flex;
	flex-direction: column;
	align-items: end;
	justify-content: end;
	top: 0;
	bottom: 0;
	background-color: #0000005e;
	max-height: 100vh;
	max-height: 100dvh;
	position: fixed;
	width: 100%;
	left: -105%;

	display: flex;
	opacity: 0;
	height: 100vh;
	transition: var(--transition-01);
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.header-box .menu-link.active {
	opacity: 1;
	left: 0;
}
